import { Menu, MenuButton, MenuList, MenuItem, Button } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import MenuPrice from "../../assets/svg/MenuPrice";

interface IMenuProps {
  products: any[];
  fetchItems: any;
}
const MenuListContent = ({ products, fetchItems }: IMenuProps) => {
  const [name, setName] = useState(products[0]?.title)
  const [priceList, setPriceList] = useState<number>(products[0]?.id);

  useEffect(() => {
    fetchItems(priceList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClick = (el: any) =>{
    fetchItems(el.id)
    setName(el.title)
  }
  return (
    <Menu >
      <MenuButton as={Button} rightIcon={<MenuPrice />} bg={'#D9D9D9'} border={'1px solid #888'} p={'10px 10px'} rounded={'10px'} w={{md:"500px", base:"100%"}} textAlign={'start'}>{name}</MenuButton>
      <MenuList>
        {products.map((el: any) => (
          <MenuItem  onClick={() => handleClick(el)} key={el.id} w={{md:"500px", base:"100%"}}>
            {el.title}
          </MenuItem>
        ))}
      </MenuList>
    </Menu>
  );
};

export default MenuListContent;
