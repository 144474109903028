import {
  Box,
  Button,
  Flex,
  Heading,
  useDisclosure,
  Modal,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import LogoSvg from "../../assets/svg/LogoSvg";
import navData from "./NavData";
import PhoneSvg from "../../assets/svg/PhoneSvg";
import { NavLink, useNavigate } from "react-router-dom";
import "./header.css";
import { useRef, useState } from "react";
import Form from "../contacts/Form";
import {  useAppSelector } from "../../hooks/useDispatch";

const Header = () => {
  const { contacts, error } = useAppSelector((s) => s.ContactsReducer);
  const navigate = useNavigate();
  const [active, setActive] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const finalRef = useRef(null);
  const handleClick = () => {
    navigate("/");
    setActive(false)
  };

  return (
    <>
      <Box
        pos={"fixed"}
        top={{ lg: "17px", base: "0" }}
        bg={{ md: "rgba(255, 255, 255, 0.20)", base: "#202020" }}
        left={{ lg: "65px", base: "0" }}
        right={{ lg: "65px", base: "0" }}
        bottom={{ lg: "", base: active ? "0" : "" }}
        zIndex={"10"}
        rounded={active ? "0" : "50px"}
        p={{ "2xl": " 0 40px", sm: "0 32px", base: "0 20px" }}
        backdropFilter={{ md: "blur(10px)", base: "none" }}
        display={"flex"}
        flexDir={{ lg: "row", base: "column" }}
        alignItems={{ lg: "center", base: "start" }}
        justifyContent={{ lg: "start", base: "center" }}
      >
        <Box
          display={{ lg: "none", base: "flex" }}
          onClick={() => setActive(!active)}
          className={`burger ${active && "active"}`}
        >
          <span></span>
        </Box>
        <Box
          display={{ lg: "block", base: "none" }}
          bg={"transparent"}
          mr={{ "2xl": "247px", xl: "200px" }}
        >
          <NavLink onClick={() => window.scroll(0, 0)} to={"/"}>
            <LogoSvg width="82px" height="82px" />
          </NavLink>
        </Box>
        <Box
          display={{ lg: "none", base: active ? "none" : "block" }}
          mx={"auto"}
        >
          <NavLink onClick={() => window.scroll(0, 0)} to={"/"}>
            <LogoSvg width="70px" height="70px" />
          </NavLink>
        </Box>
        <Flex
        
          className="nav"
          flexDirection={{ lg: "row", base: "column" }}
          display={{ lg: "flex", base: active ? "flex" : "none" }}
          gap={{ md: "10px", base: "24px" }}
          pt={{ md: "0", base: "128px" }}
        >
          {navData.map((el, idx) => (
            <Heading
            onClick={handleClick}
              key={idx}
              className="active"
              fontSize={{ md: "14px", base: "24px" }}
              color={"#FFF"}
              fontFamily={"Inter, sans-serif"}
              fontWeight={"400"}
              bg={{ md: "rgba(39, 39, 39, 0.80)", base: "transparent" }}
              rounded={"30px"}
              display={"flex"}
              p={{ md: "5px 20px", base: "20px 20px" }}
              lineHeight={"18.6px"}
              justifyContent={{ md: "center", base: "start" }}
              alignItems={"center"}
              h={"33px"}
              _active={{ bg: "#FFFFFF", color: "#4F36FF" }}
              _focus={{ bg: "#FFFFFF", color: "#4F36FF" }}
            >
              <a href={el.link}>{el.name}</a>
            </Heading>
          ))}
        </Flex>

        <Box
          pos={"absolute"}
          right={{ lg: "240px", sm: "32px", base: "15px" }}
          display={active ? "none" : "block"}
        >
          <a href={`tel:${contacts[0]?.phone_numbers[0]?.phone_number}`}>
            <PhoneSvg />
          </a>
        </Box>
        <Button
          onClick={onOpen}
          display={{ lg: "block", base: "none" }}
          pos={"absolute"}
          right={"32px"}
          rounded={"30px"}
          h={"33px"}
          border={"1px solid #FFF"}
          bg={"transparent"}
          p={"5px 22px"}
          color={"#fff"}
          fontFamily="Inter,sans-serif"
          fontSize={"14px"}
          fontWeight={"400"}
          boxShadow={'0 0 40px rgba(0 0 0 / 60%)'}
          _hover={{ transform: "scale(1.05)" , bg: "transparent"}}
      transition="transform 0.2s ease-in-out"
        >
          Оставить заявку
        </Button>
      </Box>
      <Modal finalFocusRef={finalRef} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />{" "}
        <ModalContent rounded={'18px'}>
          <Form />
        </ModalContent>{" "}
      </Modal>
    </>
  );
};

export default Header;
