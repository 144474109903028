import { IPromiseType } from "./PromiseType"

 export enum EnumProductsTypes {
    FETCH_PRODUCTS = 'FETCH_PRODUCTS',
    FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS',
    FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR'
}

export interface IinitialProducts {
    products : IPromiseType[],
    loading : boolean,
    error : null | string
}

interface IFetchProducts {
    type : EnumProductsTypes.FETCH_PRODUCTS
}
interface IFetchProductsSuccsess {
    type : EnumProductsTypes.FETCH_PRODUCTS_SUCCESS,
    payload : IPromiseType[]
}
interface IFetchProductsError {
    type : EnumProductsTypes.FETCH_PRODUCTS_ERROR,
    payload : string | null
}

export type TypeProductsAction = IFetchProducts | IFetchProductsError | IFetchProductsSuccsess