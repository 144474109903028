import { applyMiddleware, combineReducers, createStore } from "redux";
import thunk from "redux-thunk";
import { ProductReducer } from "./Reducers/Products";
import { ProductDetail } from "./Reducers/Detail";
import { ContactsReducer } from "./Reducers/Contacts";
import { SocalMediaReducer } from "./Reducers/SocalMedia";
import { FormReducer } from "./Reducers/Form";
import { ItemReducer } from "./Reducers/Item";
import { ClientsReducer } from "./Reducers/Clients";

export const rootReducer = combineReducers({
  ProductReducer,
  ProductDetail,
  ContactsReducer,
  SocalMediaReducer,
  FormReducer,
  ItemReducer,
  ClientsReducer
});
export const setUpStore = () => {
  return createStore(rootReducer, applyMiddleware(thunk));
};

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setUpStore>;
export type AppDispatch = AppStore["dispatch"];
