import { Box, Button, Heading, Flex } from "@chakra-ui/react";
import HeroSvg from "../../assets/svg/HeroSvg";
import WatsappSvg from "../../assets/svg/WatsappSvg";
import TelegramSvg from "../../assets/svg/TelegramSvg";
import InstaHeroSvg from "../../assets/svg/InstaHeroSvg";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import Application from "../application/Application";
import { useActionSocal } from "../../hooks/useActions";
import { useAppSelector } from "../../hooks/useDispatch";

const Hero = () => {
  const { fetchSocal } = useActionSocal();
  const { socalMedia, error , file} = useAppSelector((s) => s.SocalMediaReducer);
  const [modal, setModal] = useState(false);
  const navigate = useNavigate();
  const Navigate = () => {
    navigate("/price-list");
    window.scroll(0, 0);
  };

  useEffect(() => {
    fetchSocal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (error) {
    return <Box>{error}</Box>;
  }
  console.log(file, "file");

  return (
    <>
      <Box id="main" bg={"#1F1F1F"} pos={"relative"} mx={"auto"}>
        <Box className="hero">
          <Heading
            color={"#FFF"}
            fontSize={{ md: "50px", base: "28px" }}
            fontFamily={"Inter, sans-serif"}
            fontWeight={"600"}
            boxShadow={"0px 4px 20px rgba(0, 0, 0, 0.25)"}
            maxW={{ md: "686px", base: "338px" }}
            pos={"absolute"}
            left={{ "2xl": "25%", md: "190px", base: "10px" }}
            top={{ md: "286px", base: "242px" }}
            display={"flex"}
            gap={"10px"}
            lineHeight={'130%'}
          >
            <Box
              w={"3px"}
              h={"133px"}
              bg={"#fff"}
              display={{ md: "none", base: "block" }}
            />
            Поставляем  любую <br /> металлопродукцию <br /> по доступной цене
          </Heading>
          <Button
            onClick={Navigate}
            pos={"absolute"}
            left={{ "2xl": "25%", md: "190px", base: "20px" }}
            bottom={{ "2xl": "18%", md: "208px", base: "35%" }}
            bg={"#FFF"}
            rounded={"8px"}
            w={{md:"200px", base:"180px"}}
            h={{md:"60px", base:"50px"}}
            color={"#4F36FF"}
            fontSize={{md:"18px", base:"16px"}}
            fontFamily={"Inter"}
            fontWeight={700}
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            gap={"16px"}
          >
            Ассортимент <HeroSvg fill="#4F36FF" />
          </Button>
          <a
            href={file[0]?.file}
            rel="noopener noreferrer"
            download
          >
            <Button
              pos={"absolute"}
              left={{ "2xl": "25%", md: "450px", base: "20px" }}
              bottom={{ "2xl": "8%", md: "208px", base: "25%" }}
              bg={"#4F36FF"}
              rounded={"5.68px"}
              w={{"md":"286px", base:"240px"}}
              h={{md:"60px", base:"50px"}}
              color={"#FFFFFF"}
              fontSize={{md:"20px", base:"14px"}}
              fontFamily={"Inter"}
              fontWeight={700}
              justifyContent={"center"}
              alignItems={"center"}
              gap={"11px"}
              boxShadow={"0 0 40px rgba(0 0 0 / 60%)"}
              _hover={{ transform: "scale(1.05)", bg: "#4F36FF" }}
              transition="transform 0.2s ease-in-out"
            >
              Скачать Прайс-Лист <HeroSvg fill="#FFFFFF" />
            </Button>
          </a>
        </Box>
        <Flex
          flexDirection={"column"}
          gap={"23px"}
          pos={"fixed"}
          zIndex={"90"}
          bottom={"0"}
          right={"20px"}
          mb={"23px"}
        >
          <a
            href={socalMedia[0]?.whatsapp}
            target="_blank"
            rel="noreferrer"
            style={{ cursor: "pointer" }}
          >
            {" "}
            <WatsappSvg fill="#3ED47A" />
          </a>
          <a
            href={socalMedia[0]?.telegram}
            target="_blank"
            rel="noreferrer"
            style={{ cursor: "pointer" }}
          >
            <TelegramSvg fill="#07A6FF" />
          </a>
          <a
            href={socalMedia[0]?.instagram}
            target="_blank"
            rel="noreferrer"
            style={{ cursor: "pointer" }}
          >
            <InstaHeroSvg />
          </a>
        </Flex>
      </Box>
      <Box pos={"absolute"} top={modal ? "0" : "-1000px"} transition={"1s"}>
        <Application modal={modal} setModal={setModal} />
      </Box>
    </>
  );
};

export default Hero;
