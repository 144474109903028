interface IFieldsType {
    title : StaticRangeInit,
    value : string
}
interface IitemsTypes {
    id : number,
    title : string,
    fields : IFieldsType[]
}
interface Detaitypes {
    id: number,
    title: string,
    items: IitemsTypes
  }



export interface IInitialDetail {
    detail : any,
    loading : boolean,
    error : null | string
}

export enum EnumDetailAction {
    FETCH_DETAIL = 'FETCH_DETAIL',
    FETCH_DETAIL_SUCCESS = 'FETCH_DETAIL_SUCCESS',
    FETCH_DETAIL_ERROR = 'FETCH_DETAIL_ERROR'
}
interface IFetchDetail {
    type : EnumDetailAction.FETCH_DETAIL
}
interface IFetchDetailSuccsess {
    type : EnumDetailAction.FETCH_DETAIL_SUCCESS,
    payload : any
}
interface IFetchDetailError {
    type : EnumDetailAction.FETCH_DETAIL_ERROR,
    payload : string | null
}

export type TypeDetailAction = IFetchDetail | IFetchDetailSuccsess | IFetchDetailError
