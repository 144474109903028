import { Dispatch } from "react"
import { $api } from "../../Api"
import { EnumDetailAction, TypeDetailAction } from "../../types/DetailTypes"

export const fetchDetail = (id : number) => {
    return async (dispatch: Dispatch<TypeDetailAction>) => {
        try {
            dispatch({type : EnumDetailAction.FETCH_DETAIL})
            const res = await $api(`catalog/${id}/`)
            dispatch({type : EnumDetailAction.FETCH_DETAIL_SUCCESS, payload : res.data})

        }catch(e){
            dispatch({type : EnumDetailAction.FETCH_DETAIL_ERROR, payload : 'Something went wrong! '})
        }
    }
}