export interface IInitialItem {
  item: any;
  loading: boolean;
  error: null | string;
}

export enum EnumTypeItem {
  FETCH_ITEM = "FETCH_ITEM",
  FETCH_ITEM_SUCCESS = "FETCH_ITEM_SUCCESS",
  FETCH_ITEM_ERROR = "FETCH_ITEM_ERROR",
}
interface IActionItem {
  type: EnumTypeItem.FETCH_ITEM;
}
interface IActionItemSuccess {
  type: EnumTypeItem.FETCH_ITEM_SUCCESS;
  payload: any;
}
interface IActionItemError {
  type: EnumTypeItem.FETCH_ITEM_ERROR;
  payload: null | string;
}

export type TypeActionItem =
  | IActionItem
  | IActionItemSuccess
  | IActionItemError;
