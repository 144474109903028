import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import fetchProducts from "../store/Action/index";
import fetchDetail from "../store/Action/index";
import fetchContacts from "../store/Action/index";
import fetchSocal from "../store/Action/index";
import FetchForm from "../store/Action/index";
import fetchItems from "../store/Action/index";
import  fetchClients  from "../store/Action/index";

export const useActionsProducts = () => {
  const dispatch = useDispatch();
  return bindActionCreators(fetchProducts, dispatch);
};
export const useActionsDetail = () => {
  const dispatch = useDispatch();
  return bindActionCreators(fetchDetail, dispatch);
};

export const useActionContacts = () => {
  const dispatch = useDispatch();
  return bindActionCreators(fetchContacts, dispatch);
};
export const useActionSocal = () => {
  const dispatch = useDispatch();
  return bindActionCreators(fetchSocal, dispatch);
};
export const useActionForm = () => {
  const dispatch = useDispatch();
  return bindActionCreators(FetchForm, dispatch);
};

export const useActionItem = () => {
  const dispatch = useDispatch();
  return bindActionCreators(fetchItems, dispatch);
};

export const useActionClients = () => {
  const dispatch = useDispatch();
  return bindActionCreators(fetchClients, dispatch);
};