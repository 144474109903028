import { EnumTypesSocal, IInitialSocal, TypeSocalAction } from "../../types/SocalTypes";

const initialState: IInitialSocal = {
  socalMedia: {},
  loading: false,
  error: null,
  file : []
};

export const SocalMediaReducer = (
  state = initialState,
  action: TypeSocalAction
): IInitialSocal => {
  switch (action.type) {
    case EnumTypesSocal.FETCH_SOCAL: {
      return { ...state, loading: true };
    }
    case EnumTypesSocal.FETCH_SOCAL_SUCCESS: {
      return { ...state, loading: false, socalMedia: action.payload };
    }
    case EnumTypesSocal.FETCH_SOCAL_ERROR: {
      return {
        ...state,
        loading: false,
        socalMedia: [],
        error: action.payload,
      };
    }
    case EnumTypesSocal.FETCH_SOCAL_FILE : {
      return {...state, loading : false, error : '', file : action.payload}
    }
    default:
      return state;
  }
};
