import React from "react";

const ExitDetail = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="10.8076"
        y="27.7782"
        width="24"
        height="2"
        transform="rotate(-45 10.8076 27.7782)"
        fill="white"
      />
      <rect
        x="12.2218"
        y="10.8076"
        width="24"
        height="2"
        transform="rotate(45 12.2218 10.8076)"
        fill="white"
      />
    </svg>
  );
};

export default ExitDetail;
