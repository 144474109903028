import { Dispatch } from "react"
import { EnumTypesContacts, TypeContactsAction } from "../../types/ContactsTypes"
import { $api } from "../../Api"

export const fetchContacts = () =>{
    return async (dispatch: Dispatch<TypeContactsAction>) =>{
        try {
            dispatch({type : EnumTypesContacts.FETCH_CONTACTS})
            const res = await $api(`contacts/`)
            dispatch({type : EnumTypesContacts.FETCH_CONTACTS_SUCCESS, payload: res.data})
        }catch(e){
            dispatch({type: EnumTypesContacts.FETCH_CONTACTS_ERROR, payload: 'Somthing went wrong!'})
        }
    }
}