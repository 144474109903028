export interface IInitialSocal {
    socalMedia: any;
    loading: boolean;
    error: null | string;
    file : any[]
  }
  
 export  enum EnumTypesSocal {
    FETCH_SOCAL = "FETCH_SOCAL",
    FETCH_SOCAL_SUCCESS = "FETCH_SOCAL_SUCCESS",
    FETCH_SOCAL_ERROR = "FETCH_SOCAL_ERROR",
    FETCH_SOCAL_FILE = 'FETCH_SOCAL_FILE'
  }
  
  interface IFetchSocal {
    type: EnumTypesSocal.FETCH_SOCAL;
  }
  interface IFetchSocalSuccess {
    type: EnumTypesSocal.FETCH_SOCAL_SUCCESS;
    payload: any;
  }
  interface IFetchSocalFile {
    type: EnumTypesSocal.FETCH_SOCAL_FILE;
    payload: any[];
  }
  interface IFetchSocalError {
    type: EnumTypesSocal.FETCH_SOCAL_ERROR;
    payload: string | null;
  }
  export type TypeSocalAction =
    | IFetchSocal
    | IFetchSocalSuccess
    | IFetchSocalError
    | IFetchSocalFile
