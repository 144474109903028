import { EnumDetailAction, IInitialDetail, TypeDetailAction } from "../../types/DetailTypes"

const initialState:IInitialDetail = {
    detail : {},
    loading : false,
    error : null
}
export const ProductDetail = (state = initialState, action : TypeDetailAction ) :IInitialDetail =>{
    switch(action.type){
        case EnumDetailAction.FETCH_DETAIL : {
            return {...state , loading : true}
        }
        case EnumDetailAction.FETCH_DETAIL_SUCCESS : {
            return {...state , loading : false, detail : action.payload}
        }
        case EnumDetailAction.FETCH_DETAIL_ERROR : {
            return {...state , loading : false, detail : {}, error : action.payload } 
        }
        default : return state
    }
}
