const EmailSvg = () => {
  return (
    <svg
      width="23"
      height="18"
      viewBox="0 0 23 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M22.0018 2.2C22.0018 0.99 21.0118 0 19.8018 0H2.20177C0.99177 0 0.00177002 0.99 0.00177002 2.2V15.4C0.00177002 16.61 0.99177 17.6 2.20177 17.6H19.8018C21.0118 17.6 22.0018 16.61 22.0018 15.4V2.2ZM19.8018 2.2L11.0018 7.7L2.20177 2.2H19.8018ZM19.8018 15.4H2.20177V4.4L11.0018 9.9L19.8018 4.4V15.4Z"
        fill="white"
      />
    </svg>
  );
};

export default EmailSvg;
