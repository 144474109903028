import "./App.css";
import { extendTheme, ChakraBaseProvider, Box } from "@chakra-ui/react";
import { Layout } from "./components/layout/Layout";
import { ReactNode, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import AboutPage from "./pages/about/AboutPage";
import CatalogPage from "./pages/catalog/CatalogPage";
import PricePrice from "./pages/priceList/PricePage";
import { useActionsProducts } from "./hooks/useActions";
import Main from "./pages/main";

type LayoutChildren = ReactNode | ReactNode[];

function App() {
  const { fetchProducts } = useActionsProducts();
  const breakpoints = {
    base: "320px",
    sm: "390px",
    md: "768px",
    lg: "960px",
    xl: "1200px",
    "2xl": "1536px",
  };

  const theme = extendTheme({ breakpoints });

  useEffect(() => {
    fetchProducts();
  }, []);
  return (
    <Box maxW={"2000px"}>
      <ChakraBaseProvider theme={theme}>
        <Layout<LayoutChildren>>
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/products" element={<CatalogPage />} />
            <Route path="/price-list" element={<PricePrice />} />
          </Routes>
        </Layout>
      </ChakraBaseProvider>
    </Box>
  );
}

export default App;
