import { Box, Flex, Heading } from "@chakra-ui/react";
import LogoSvg from "../../assets/svg/LogoSvg";
import navData from "../header/NavData";
import WatsappSvg from "../../assets/svg/WatsappSvg";
import TelegramSvg from "../../assets/svg/TelegramSvg";
import InstagramSvg from "../../assets/svg/InstagramSvg";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../hooks/useDispatch";
import LocationSvg from "../../assets/svg/LocationSvg";
import PhoneLocSvg from "../../assets/svg/PhoneLocSvg";
import EmailSvg from "../../assets/svg/EmailSvg";

const Footer = () => {
  const { contacts, error } = useAppSelector((s) => s.ContactsReducer);
  const { socalMedia } = useAppSelector((s) => s.SocalMediaReducer);
  return (
    <Box>
      <Flex
        bg={"#202020"}
        p={"20px 0 35px 0"}
        flexDirection={{ sm: "row", base: "column" }}
        gap={"36px"}
        alignItems={{ sm: "start", base: "center" }}
        justifyContent={"space-around"}
      >
        <Flex
          flexDirection="column"
          alignItems={{ sm: "start", base: "center" }}
        >
          <NavLink onClick={() => window.scroll(0, 0)} to={"/"}>
            <LogoSvg width="100px" height="100px" />
          </NavLink>
          <Flex gap={"23px"}>
            <a
              href={socalMedia[0]?.whatsapp}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              <WatsappSvg fill="#FFFFFF" />
            </a>
            <a
              href={socalMedia[0]?.telegram}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              <TelegramSvg fill="#FFFFFF" />
            </a>
            <a
              href={socalMedia[0]?.instagram}
              target="_blank"
              rel="noreferrer"
              style={{ cursor: "pointer" }}
            >
              <InstagramSvg fill="#FFFFFF" />
            </a>
          </Flex>
        </Flex>

        <Flex gap={"66px"}>
          <Flex
            flexDirection={"column"}
            gap={{ md: "13px", base: "8px" }}
            justifyContent={{ md: "start", base: "center" }}
            alignItems={{ md: "start", base: "center" }}
          >
            <Flex
              gap="16px"
              alignItems={"center"}
              color={"#FFF"}
              fontSize={{ md: "16px", base: "13px" }}
              fontFamily={"Inter,sans-serif"}
              fontWeight={"400"}
            >
              <Box display={{ md: "block", base: "none" }}>
                <LocationSvg />
              </Box>{" "}
              {contacts[0]?.address}
            </Flex>
            <Flex
              gap="16px"
              alignItems={"center"}
              color={"#FFF"}
              fontSize={{ md: "16px", base: "13px" }}
              fontFamily={"Inter,sans-serif"}
              fontWeight={"400"}
            >
              <Box display={{ md: "block", base: "none" }}>
                <PhoneLocSvg />
              </Box>
              <Flex flexDirection={"column"} alignItems={"center"}>
                {contacts[0]?.phone_numbers.map((el: any, idx: number) => (
                  <a href={`tel:${el.phone_number}`} key={idx}>
                    {el?.phone_number}
                  </a>
                ))}
              </Flex>
            </Flex>
            <Flex
              gap="16px"
              alignItems={"center"}
              color={"#FFF"}
              fontSize={{ md: "16px", base: "13px" }}
              fontFamily={"Inter,sans-serif"}
              fontWeight={"400"}
            >
              <Box display={{ md: "block", base: "none" }}>
                <EmailSvg />
              </Box>{" "}
              Пн-Сб 8:30 - 18:00
            </Flex>
          </Flex>
          <Flex
            gap={"12px"}
            display={{ lg: "flex", base: "none" }}
            flexDirection="column"
          >
            {navData.map((el, idx) => (
              <Heading
                key={idx}
                color={"#F2F2F4"}
                fontSize={"14px"}
                fontFamily={"Inter,sans-serif"}
                fontWeight={"400"}
              >
                <a href={el.link}>{el.name}</a>
              </Heading>
            ))}
          </Flex>
        </Flex>
      </Flex>
      <Box
        bg={"#000"}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
        p={{ lg: "25px 0", base: "8px 0" }}
      >
        <Heading
          color={"#FFF"}
          fontFamily={"Inter,sans-serif"}
          fontWeight={"400"}
          fontSize={{ lg: "16px", base: "10px" }}
          opacity={0.5}
        >
          Metallomir © 2020-2024. Все права защищены
        </Heading>
      </Box>
    </Box>
  );
};

export default Footer;
