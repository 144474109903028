import { Box, Container, Flex, Heading } from "@chakra-ui/react";
import Form from "./Form";
import LocationSvg from "../../assets/svg/LocationSvg";
import PhoneLocSvg from "../../assets/svg/PhoneLocSvg";
import EmailSvg from "../../assets/svg/EmailSvg";
import { useLocation } from "react-router-dom";
import { useActionContacts } from "../../hooks/useActions";
import { useEffect } from "react";
import { useAppSelector } from "../../hooks/useDispatch";

const Contacts = () => {
  const { pathname } = useLocation();
  const { fetchContacts } = useActionContacts();

  useEffect(() => {
    fetchContacts();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box id="contacts" bg={"#1F1F1F"} pos={"relative"}>
      <Box className={pathname === "/products" ? "" : "contacts"}>
        <Container
          maxW={"1100px"}
          m={"0 auto"}
          p={{ md: "95px 0", base: "30px 0" }}
          display={"flex"}
          flexDirection={{ md: "row", base: "column" }}
        >
          <Heading
            display={{ md: "none", base: "block" }}
            color={"#fff"}
            textShadow={" 0px 4px 20px rgba(0, 0, 0, 0.25)"}
            fontSize={"35px"}
            fontFamily={"HelveticaBold"}
            textAlign={"center"}
          >
            Контакты
          </Heading>
          <Form />

          <Box
            pos={"relative"}
            bg={{ md: "rgba(255, 255, 255, 0.15)", base: "transparent" }}
            rounded={"0px 18px 18px 0px"}
            border={{ md: "1px solid #FFF", base: "none" }}
            backdropBlur={"blur(15px)"}
          >
            {/* eslint-disable-next-line jsx-a11y/iframe-has-title */}
            <iframe
              className="iframe"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d2728.503292055043!2d74.56644987700969!3d42.865723331048734!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x389ec847ee362293%3A0x5a793657127b5aef!2zMTkg0YPQuy4g0JvRjNCy0LAg0KLQvtC70YHRgtC-0LPQviwg0JHQuNGI0LrQtdC6IDcyMDAwNw!5e0!3m2!1sru!2skg!4v1695694699111!5m2!1sru!2skg"
              width="650px"
              height="100%"
              style={{ border: "none" }}
              loading="lazy"
            ></iframe>
          </Box>
        </Container>
      </Box>
    </Box>
  );
};

export default Contacts;
