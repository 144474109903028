import React from "react";

const InstaHeroSvg = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="40" rx="20" fill="#413F3F" />
      <path
        d="M24.0044 9.74963H14.9959C11.0829 9.74963 8.75012 12.0824 8.75012 15.9954V24.9931C8.75012 28.9169 11.0829 31.2496 14.9959 31.2496H23.9936C27.9066 31.2496 30.2394 28.9169 30.2394 25.0039V15.9954C30.2501 12.0824 27.9174 9.74963 24.0044 9.74963ZM19.5001 24.6706C17.1996 24.6706 15.3291 22.8001 15.3291 20.4996C15.3291 18.1991 17.1996 16.3286 19.5001 16.3286C21.8006 16.3286 23.6711 18.1991 23.6711 20.4996C23.6711 22.8001 21.8006 24.6706 19.5001 24.6706ZM25.8641 14.9956C25.8104 15.1246 25.7351 15.2429 25.6384 15.3504C25.5309 15.4471 25.4126 15.5224 25.2836 15.5761C25.1546 15.6299 25.0149 15.6621 24.8751 15.6621C24.5849 15.6621 24.3161 15.5546 24.1119 15.3504C24.0151 15.2429 23.9399 15.1246 23.8861 14.9956C23.8324 14.8666 23.8001 14.7269 23.8001 14.5871C23.8001 14.4474 23.8324 14.3076 23.8861 14.1786C23.9399 14.0389 24.0151 13.9314 24.1119 13.8239C24.3591 13.5766 24.7354 13.4584 25.0794 13.5336C25.1546 13.5444 25.2191 13.5659 25.2836 13.5981C25.3481 13.6196 25.4126 13.6519 25.4771 13.6949C25.5309 13.7271 25.5846 13.7809 25.6384 13.8239C25.7351 13.9314 25.8104 14.0389 25.8641 14.1786C25.9179 14.3076 25.9501 14.4474 25.9501 14.5871C25.9501 14.7269 25.9179 14.8666 25.8641 14.9956Z"
        fill="white"
      />
    </svg>
  );
};

export default InstaHeroSvg;
