import { IProps } from "./Types";

const CardSvg = ({hover, width, height}: IProps) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 230 78"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 4C0 2.11438 0 1.17157 0.585786 0.585786C1.17157 0 2.11438 0 4 0H226C227.886 0 228.828 0 229.414 0.585786C230 1.17157 230 2.11438 230 4V50C230 63.1993 230 69.799 225.899 73.8995C221.799 78 215.199 78 202 78H31.902C28.5001 78 26.7991 78 25.2823 77.3457C23.7654 76.6914 22.5982 75.4541 20.2637 72.9795L4.36171 56.1234C2.20747 53.8399 1.13035 52.6982 0.565177 51.2755C0 49.8528 0 48.2831 0 45.1439V4Z"
        fill={hover ? '#072A6A' : '#F2F2F4'}
      />
    </svg>
  );
};

export default CardSvg;
