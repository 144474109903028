import {
  Box,
  Container,
  Flex,
  Heading,
  Text,
} from "@chakra-ui/react";
import PoginationSvg from "../../assets/svg/PoginationSvg";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "../../hooks/useDispatch";
import { useActionItem } from "../../hooks/useActions";
import MenuListContent from "./MenuListContent";
import Skeleton from "../skeleton/Skeleton";

const PriceList = () => {

  const { item, loading } = useAppSelector((s) => s.ItemReducer);
  const { products } = useAppSelector((s) => s.ProductReducer);
  const { fetchItems } = useActionItem();

  return (
    <Box bg={"#1F1F1F"}>
      <Container
        maxW={"1117px"}
        mx={"auto"}
        p={{ md: "226px 0 86px 0", base: "101px 20px 20px 20px" }}
      >
        <Flex
          display={{ md: "flex", base: "none" }}
          gap={"16px"}
          alignItems={"center"}
          color={"#FFF"}
          fontSize={"16px"}
          fontFamily={"Inter, sans-serif"}
          fontWeight={"400"}
        >
          <NavLink to={"/"}>Главная</NavLink>
          <PoginationSvg />
          <NavLink to={"/products"}>Каталог</NavLink>
          <PoginationSvg />
          Ассортимент
        </Flex>
        <Heading
          color={"#fff"}
          textShadow={"0px 4px 20px rgba(0, 0, 0, 0.25)"}
          fontSize={{ md: "60px", base: "30px" }}
          textAlign={{ md: "start", base: "center" }}
          fontFamily={"HelveticaBold"}
          m={{ md: "22px 0 80px 0", base: "20px  0 20px 0" }}
        >
          Ассортимент
        </Heading>

      {products.length > 0 && <MenuListContent products={products} fetchItems={fetchItems}/>}
      {loading && <Box mt={{md: "70px", base: "30px" }}><Skeleton variant="rect" width={1000} height={500} /></Box>}

        <Box mt={{ md: "70px", base: "30px" }}  rounded={'8px 8px 0 0 '}>
          {item?.items?.map((el: any) => (
            <Flex
            
              flexDirection={"column"}
              justifyContent={"start"}
              alignItems={"start"}
              w={"full"}

              overflowY={"auto"}
            >
              <Heading
                w={"full"}
                bg={"#3A3939"}
                p={{ md: "17px 30px", base: "10px" }}
                color={"#FFF"}
               
                fontFamily={"HelveticaBold"}
                fontSize={{ md: "20px", base: "12px" }}
                border={" 1px solid #6B6B6B"}
              >
                {el?.title}
              </Heading>
              <Flex w={"full"}>
                {el?.fields?.map((el: any) => (
                  <Flex flexDirection={"column"} w={"full"}>
                    <Heading
                      p={{ md: "15px 19px", base: "7px" }}
                      h={{md:"60px", base:"40px"}}
                      color={"#072A6A"}
                      fontSize={{ md: "16px", base: "10px" }}
                      fontFamily={"HelveticaBold"}
                      border={"1px solid #B3B3B3"}
                      bg={"#fff"}
                      textAlign={"center"}
                    >
                      {el?.title}
                    </Heading>
                    <Text
                      color={"#1F1F1F"}
                      fontSize={{ md: "16px", base: "8px" }}
                      fontFamily={"HelveticaBold"}
                      border={"1px solid #B3B3B3"}
                      bg={"#ccc"}
                      textAlign={"center"}
                      h={{ md: "60px", base: "36px" }}
                      display={"flex"}
                      justifyContent={"center"}
                      alignItems={"center"}
                    >
                      {el?.value}
                    </Text>
                  </Flex>
                ))}
              </Flex>
            </Flex>
          ))}
        </Box>
      </Container>
    </Box>
  );
};

export default PriceList;
