import {
  Box,
  Input,
  Textarea,
  Text,
  FormLabel,
  Heading,
  Button,
  InputLeftAddon,
  InputGroup,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useAppSelector } from "../../hooks/useDispatch";
import { useActionForm } from "../../hooks/useActions";
import { useState } from "react";
import Success from "../../assets/svg/Success";

export interface IForm {
  name: string;
  phone_number: number;
  message: string;
}

const Form = () => {
  const { form } = useAppSelector((s) => s.FormReducer);
  const [success, setSuccess] = useState(false);
  const { FetchForm } = useActionForm();
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm({
    mode: "onBlur",
  });

  const onSubmit = (data: any): void => {
    FetchForm(data);
    console.log(data, "data");
    reset();
    if (form) {
      setTimeout(() => setSuccess(true), 1000);
      setTimeout(() => setSuccess(false), 3000);
    }
  };

  return (
    <Box minW={{ md: "400px", base: "" }}>
      {success ? (
        <Box
          display={"flex"}
          flexDirection={"column"}
          gap={"30px"}
          justifyContent={"center"}
          alignItems={"center"}
          bg={"#202020"}
          p={"50px"}
          rounded={"8px"}
        >
          <Heading
            color={"#ffffff"}
            fontSize={{ md: "40px", base: "16px" }}
            p={"20px 0"}
            textAlign={"center"}
          >
            Спасибо за обратную связь!
          </Heading>
          <Success />
        </Box>
      ) : (
        <Box
          maxW={{ lg: "448px", base: "100%" }}
          border={"1px solid #A19D9D"}
          rounded={{ md: "18px 0px 0px 18px", base: "14px" }}
          p={{ md: "33px 25px 28px 24px", base: "13px 10px" }}
          m={{ md: "0", base: "40px 20px" }}
          bg={"#202020"}
        >
          <Heading
            color={"#FFF"}
            fontFamily={{ md: "Inter,sans-serif", base: "HelveticaBold" }}
            fontWeight={{ md: "500", base: "700" }}
            textAlign={{ md: "start", base: "center" }}
            fontSize={{ md: "35px", base: "26px" }}
          >
            Оставить заявку
          </Heading>
          <form
            onSubmit={handleSubmit(onSubmit)}
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <Box
              mb={{ md: "25px", base: "20px" }}
              mt={{ md: "25px", base: "20px" }}
            >
              <FormLabel
                color={"#CCC6C6"}
                fontSize={"12px"}
                fontFamily={"Inter,sans-serif"}
                fontWeight={"400"}
              >
                Имя
              </FormLabel>
              <Input
                {...register("name", {
                  required: true,
                })}
                type="text"
                placeholder="Антон"
                bg={"#343333"}
                border={"0.742px solid #8D8D8D"}
                rounded={"5px"}
                padding={{ md: "14.848px 23.757px", base: "12px" }}
                color={"#ffffff"}
                w={"100%"}
              />
              <Box pos="absolute">
                {errors?.name && (
                  <Text color={"red"} fontSize={"11px"}>
                    Поле обьязательно к заполнению
                  </Text>
                )}
              </Box>
            </Box>
            <Box pos="relative" mb={{ md: "25px", base: "20px" }}>
              <FormLabel
                color={"#CCC6C6"}
                fontSize={"12px"}
                fontFamily={"Inter,sans-serif"}
                fontWeight={"400"}
              >
                Номер
              </FormLabel>
                <Input
                  {...register("phone_number", {
                    required: true,
                    pattern: {
                      value: /^[\d+]+$/,
                      message: "Пожалуйста, введите допустимый номер телефона.",
                    },
                  })}
                  type="text"
                  fontSize={"14px"}
                  maxH={"46px"}
                  placeholder="+000 000000000"
                  bg={"#343333"}
                  border={"0.742px solid #8D8D8D"}
                  rounded={"5px"}
                  padding={{ md: "14.848px 23.757px", base: "12px" }}
                  color={"#ffffff"}
                />
              <Box pos="absolute">
                {errors?.phone_number && (
                  <Text color={"red"} fontSize={"11px"}>
                    Поле обьязательно к заполнению
                  </Text>
                )}
              </Box>
            </Box>

            <Box>
              <FormLabel
                color={"#CCC6C6"}
                fontSize={"12px"}
                fontFamily={"Inter,sans-serif"}
                fontWeight={"400"}
              >
                Сообщение
              </FormLabel>
              <Textarea
                {...register("message")}
                placeholder="Введите свой запрос здесь....."
                mb={{ md: "25px", base: "20px" }}
                border={" 0.742px solid #8D8D8D"}
                bg={"#343333"}
                rounded={"5px"}
                color={"#fff"}
              />
            </Box>
            <Button
              type="submit"
              bg={"#3A4EFF"}
              _hover={{ bg: "#3A4EFF" }}
              rounded={"14px"}
              color={"#fff"}
              fontSize={"22px"}
              border={"none"}
              outline={"none"}
              minH={"63px"}
            >
              отправить
            </Button>
          </form>
        </Box>
      )}
    </Box>
  );
};

export default Form;
