import * as FetchAction from './Products'
import * as FetchDetail from './Detail'
import * as FetchContacts from './Contacts'
import * as FetchSocal from './SocalMedia'
import * as FetchForm from './Form'
import * as FetchItems from './Item'
import * as FetchClients from './Clients'

// eslint-disable-next-line import/no-anonymous-default-export
export default {
        ...FetchAction,
        ...FetchDetail,
        ...FetchContacts,
        ...FetchSocal,
        ...FetchForm,
        ...FetchItems,
        ...FetchClients
  };