import Header from "../header/Header";


interface LayoutProps<T> {
  children: T;
}

export function Layout<T>({ children }: LayoutProps<T>) {
  return (
    <>
      <Header />
      {children}
    </>
  );
}