import { Flex, Heading, Box, Image,useDisclosure,ScaleFade ,Button,Text, Slide} from "@chakra-ui/react";
import CardSvg from "../../assets/svg/CardSvg";
import { useState } from "react";
import Deiail from "../detail/Deiail";
import { useActionsDetail } from "../../hooks/useActions";
import ExitDetail from "../../assets/svg/ExitDetail";
import { useAppSelector } from "../../hooks/useDispatch";
import { useNavigate } from "react-router-dom";

interface ICard {
  image: string | any;
  name: string;
  id: number;
  key : number;
}

const Card = ({ image, name, id , key}: ICard) => {
  const { isOpen, onToggle } = useDisclosure()
  const {fetchDetail} = useActionsDetail()
  const { detail, error } = useAppSelector((s) => s.ProductDetail);
  const navigate = useNavigate();
  const [modal, setModal] = useState(false)
  const [hover, setHover] = useState(false);
  const handleClick = (id : number) =>{
    onToggle()
   fetchDetail(id)
  }
  const handleLink = () => {
    navigate("/price-list");
    window.scroll(0, 0);
  };
  if (error) {
    return <Heading>{error}</Heading>;
  }
  return (
    <>
      <Flex   onClick={()=> handleClick(id)} cursor={'pointer'} key={key} flexDirection="column" rounded={"20px 20px  0 0 "}>
      <Image src={image} alt="qwert" w={{md:"230px", base:"156px"}} h={{md:"220px", base:"152px"}} objectFit={'cover'} rounded={'20px 20px 0 0'}/>
      <Flex
        onMouseOver={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        cursor={"pointer"}
        pos={"relative"}
        flexDirection={"column"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Box onClick={()=> handleClick(id)}  display={{md:"block", base:"none"}}>
           <CardSvg width="230px" height="78px" hover={hover} />
        </Box>
        <Box onClick={()=> handleClick(id)} display={{md:"none", base:"block"}}>
           <CardSvg width="158px" height="54px" hover={hover} />
        </Box>
        <Heading
        onClick={()=> handleClick(id)}
          pos={"absolute"}
          top={{md:"30px", base:"5px"}}
          bottom={'5px'}
          color={hover ? "#fff" : "#1F1F1F"}
          fontSize={"16px"}
          fontWeight={"700"}
          textAlign={'center'}
          fontFamily={"HelveticaBold"}
        >
          {name}
        </Heading>
      </Flex>
    </Flex>
    <Slide direction='bottom' in={isOpen} style={{ zIndex: 10 }}>
        <Box
       pos={'relative'}
        >
            <Box
        w={{ lg: "100%", base: "100%" }}
        h={{md:"60vh", base:"90vh"}}
       overflow={'scroll'}
        bg="#1F1F1F"
        p={{ lg: "75px 30px", md: "40px 20px", base: "20px 20px" }}
        rounded="20px"
     
        display={"flex"}
        flexDirection={{ lg: "row", base: "column" }}
        justifyContent={"center"}
        alignItems={"center"}
        gap={{ lg: "30px", md: "20px", base: "5px 0" }}
      >
        <Box
          onClick={onToggle}
          pos={"absolute"}
          top={"20px"}
          right={"20px"}
          cursor={"pointer"}
          bg={{md:"transparent", base:"#000"}}
          rounded={'50%'}
        >
          <ExitDetail />
        </Box>

        <Image
          src={detail?.image}
          alt="image"
          objectFit={"cover"}
          w={{ md: "400px", base: "100%" }}
          maxH={{ md: "361px", base: "30%" }}
          
          rounded={"20px"}
          bgRepeat={'no-repeat'}
        />
        <Box>
          <Flex
            justifyContent={{ md: "space-between", base: "start" }}
            alignItems={"center"}
          >
            <Heading
              color={"#F2F2F4"}
              fontSize={{ md: "32px", base: "22px" }}
              fontFamily={"HelveticaBold"}
            >
              {detail?.title}
            </Heading>
            <Button
              onClick={handleLink}
              display={{ md: "block", base: "none" }}
              color={"#fff"}
              fontSize={"14px"}
              fontFamily={"Inter, sans-serif"}
              fontWeight={"400"}
              p={"3px 12px"}
              rounded={"30px"}
              border={"1px solid #F2F2F4"}
              bg={"transparent"}
              _hover={{ bg: "transparent" }}
            >
              Прайс-лист
            </Button>
          </Flex>
          <Text
            color={"#F2F2F4"}
            fontFamily={"Inter"}
            fontWeight={"300"}
            p={{ md: "45px 0 93px 0", base: "20px 0 " }}
            w={{ md: "515px", base: "100%" }}
            fontSize={{md:"14px", base:"12px"}}
            textShadow={" 0px 4px 20px rgba(0, 0, 0, 0.25)"}
          >
            {detail?.description}
          </Text>
          <a href={`mailto:vlad@webref.ru`}>
               <Text
               _hover={{textDecoration:"underline"}}
            color={"#059EFF"}
            fontFamily={"HelveticaRegular"}
            fontSize={{md:"14px", base:"12px"}}
            w={{ md: "515px", base: "100%" }}
          >
            Мы ценим ваш интерес к нашему товару и готовы помочь вам быстро
            уточнить его наличие. Напишите нам на почту Благодарим за выбор!
          </Text>
          </a>
       
          <Button
            onClick={handleLink}
            display={{ md: "none", base: "block" }}
            color={"#fff"}
            fontSize={"14px"}
            fontFamily={"Inter, sans-serif"}
            fontWeight={"400"}
            p={"5px 12px"}
            rounded={"30px"}
            border={"1px solid #F2F2F4"}
            bg={"transparent"}
            w={"180px"}
            m={"20px auto"}
            _hover={{ bg: "transparent" }}
          >
            Прайс-лист
          </Button>
        </Box>
      </Box>
        </Box>
      </Slide>
    {/* <Box pos={'absolute'} top={modal ? '1px' : "-800px"} transition={'2s'}><Deiail modal={modal} setModal={setModal} /></Box> */}
    </>
  );
};

export default Card;
