import { Dispatch } from "react";
import { EnumTypesSocal, TypeSocalAction } from "../../types/SocalTypes";
import { $api } from "../../Api";
import axios from "axios";

export const fetchSocal = () => {
  return async (dispatch: Dispatch<TypeSocalAction>) => {
    try {
      dispatch({ type: EnumTypesSocal.FETCH_SOCAL });
      const res = await $api("social-media/");
      dispatch({ type: EnumTypesSocal.FETCH_SOCAL_SUCCESS, payload: res.data });
      const file = await axios(`https://metallomir.pythonanywhere.com/api/v1/main_price_file`);
      dispatch({ type: EnumTypesSocal.FETCH_SOCAL_FILE, payload: file.data });
    } catch (e) {
      dispatch({
        type: EnumTypesSocal.FETCH_SOCAL_ERROR,
        payload: "Something went wrong!",
      });
    }
  };
};
