import Contacts from "../../components/contacts/Contacts"
import Footer from "../../components/footer/Footer"
import PriceList from "../../components/priceList/PriceList"

const PricePrice = () => {
  return (
<>
    <PriceList/>
    <Contacts/>
    <Footer/>
</>
  )
}

export default PricePrice