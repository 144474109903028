import "./Skeleton.css";

interface SkeletonTextProps {
  variant: "text";
}

interface SkeletonShapeProps {
  variant: "rect" | "circle";
  width: number;
  height: number;
}

type SkeletonProps = SkeletonTextProps | SkeletonShapeProps;

export default function Skeleton(props: SkeletonProps) {
  let style: any = {};
  if (props.variant !== "text") {
    if (window.innerWidth < 768) {
      style.width = "90vw";
      style.height = "200px";
    } else {
      style.width = props.width;
      style.height = props.height;
    }
  }

  return <div className={`Skeleton Skeleton-${props.variant}`} style={style} />;
}
