import { EnumTypeForm, IInitialForm, TypedActionForm } from "../../types/FormTypes"

const initialState:IInitialForm = {
    loading : false,
    form : [],
    error : null
}


export const FormReducer = (state = initialState, action:TypedActionForm):IInitialForm =>{
    switch(action.type){
        case EnumTypeForm.FETCH_FORM :{
            return {...state, loading : true}
        }
        case EnumTypeForm.FETCH_FORM_SUCCESS :{
            return {...state, loading : false, form : action.payload}
        }
        case EnumTypeForm.FETCH_FORM_ERROR :{
            return {...state, loading : false, form : [], error : action.payload}
        }
        default : return state
    }
}
