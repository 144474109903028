
export interface IInitialClients {
    clients : any[],
    loading : boolean,
    error : null | string,
    providers : any[]
}

export enum EnumTypesClients  {
    FETCH_CLIENTS = 'FETCH_CLIENTS',
    FETCH_CLIENTS_SUCCESS = 'FETCH_CLIENTS_SUCCESS',
    FETCH_CLIENTS_ERROR = 'FETCH_CLIENTS_ERROR',
    FETCH_CLIENTS_PROVIDERS = 'FETCH_CLIENTS_PROVIDERS'
}

interface IFetchClients {
    type : EnumTypesClients.FETCH_CLIENTS
}

interface IFetchClientsSuccess {
    type : EnumTypesClients.FETCH_CLIENTS_SUCCESS,
    payload : any[]
}
interface IFetchClientsError {
    type : EnumTypesClients.FETCH_CLIENTS_ERROR,
    payload : null | string
}
interface IFetchClientsProviders {
    type : EnumTypesClients.FETCH_CLIENTS_PROVIDERS,
    payload : any[]
}
export type TypeClientsAction = IFetchClients | IFetchClientsSuccess | IFetchClientsError | IFetchClientsProviders