import { Dispatch } from "react";
import { EnumTypeItem, TypeActionItem } from "../../types/Item";
import { $api } from "../../Api";

export const fetchItems = (id: number) => {
  return async (dispatch: Dispatch<TypeActionItem>) => {
    try {
      dispatch({ type: EnumTypeItem.FETCH_ITEM });
      const res = await $api(`catalog_items/${id}/`);
      dispatch({ type: EnumTypeItem.FETCH_ITEM_SUCCESS, payload: res.data });
    } catch (e) {
      dispatch({
        type: EnumTypeItem.FETCH_ITEM_ERROR,
        payload: "server Error...",
      });
    }
  };
};
