import { Dispatch } from "react"
import { $api } from "../../Api"
import { EnumProductsTypes, TypeProductsAction } from "../../types/ProductsTypes"

export const fetchProducts = () => {
    return async (dispatch: Dispatch<TypeProductsAction>) => {
        try {
            dispatch({type : EnumProductsTypes.FETCH_PRODUCTS})
            const res = await $api(`catalog/`)
            dispatch({type : EnumProductsTypes.FETCH_PRODUCTS_SUCCESS, payload : res.data})

        }catch(e){
            dispatch({type : EnumProductsTypes.FETCH_PRODUCTS_ERROR, payload : 'Something went wrong!'})
        }
    }
}