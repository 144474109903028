const navData = [
    {
      name: 'Главная',
      link: '#main'
    },
    {
      name: 'Продукции',
      link: '#products'
    },
    {
      name: 'О компании',
      link: '#about'
    },
    {
      name: 'Услуги',
      link: '#services'
    },
    {
      name: 'Контакты',
      link: '#contacts'
    },
  
  ];
  
  export default navData;