import { Box, Heading } from "@chakra-ui/react";
import Form from "../contacts/Form";

interface IDetailProps {
  modal: boolean;
  setModal: (modal: boolean) => void;
}

const Application = ({ modal, setModal }: IDetailProps) => {
  return (
    <Box>
      <Box
        onClick={() => setModal(false)}
        transition="1s"
        position={modal ? "fixed" : "absolute"}
        top="0"
        bottom="0"
        right="0"
        left="0"
        bg="#5b5b5b3d"
        zIndex="99"
        w="100%"
      />
      <Box
        position="fixed"
        zIndex="99"
        bottom={{ lg: modal ? "18%" : "-800px",  base:modal ? "0" : "-800px" }}
        left={{ "2xl": "35%", xl: "33%", lg: "25%",base:"0" }}
        transition={"1s"}
        bg={"#202020"}
        border={" 1px solid #A19D9D"}
        rounded={"18px 18px 0 0 "}
        p={{md:"50px", sm:"00px", base:"0px"}}
        w={{md:"500px", base:"100%"}}
      >

        <Form />
      </Box>
    </Box>
  );
};

export default Application;