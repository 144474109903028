export interface IInitialForm {
    loading : boolean,
    form : any[],
    error : null | string
}

export enum EnumTypeForm {
    FETCH_FORM = 'FETCH_FORM',
    FETCH_FORM_SUCCESS = 'FETCH_FORM_SUCCESS',
    FETCH_FORM_ERROR = 'FETCH_FORM_ERROR'
}
interface ActionForm {
    type:EnumTypeForm.FETCH_FORM
}
interface ActionFormSuccess {
    type:EnumTypeForm.FETCH_FORM_SUCCESS,
    payload: any[]
}
interface ActionFormError {
    type:EnumTypeForm.FETCH_FORM_ERROR,
    payload : null | string
}

export type TypedActionForm = ActionForm | ActionFormSuccess | ActionFormError