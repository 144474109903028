const ShowMoreSvg = () => {
  return (
    <svg
    width="27"
    height="44"
    viewBox="0 0 27 44"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.1355 14L22.7224 20.385L17.1355 26.77"
      stroke="white"
      strokeWidth="1.243"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
  )
}

export default ShowMoreSvg