import { Dispatch } from "react";

import axios from "axios";
import { EnumTypeForm, TypedActionForm } from "../../types/FormTypes";
import { IForm } from "../../components/contacts/Form";


export const FetchForm = (data:IForm) => {
  return async (dispatch: Dispatch<TypedActionForm>) => {
    try {
      dispatch({ type: EnumTypeForm.FETCH_FORM });

      const responce = await axios.post(`https://metallomir.pythonanywhere.com/api/v1/callbacks/`, data);
      dispatch({ type: EnumTypeForm.FETCH_FORM_SUCCESS, payload: responce.data });
    } catch (e: any) {
      dispatch({
        type: EnumTypeForm.FETCH_FORM_ERROR,
        payload: e?.response?.status,
      });
    }
  };
};
