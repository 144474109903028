import AboutVideo from "../../components/AboutVideo/AboutVideo"
import AboutJobs from "../../components/aboutJobs/AboutJobs"
import Contacts from "../../components/contacts/Contacts"
import Footer from "../../components/footer/Footer"

const AboutPage = () => {
  return (
    <>
    <AboutVideo/>
    <AboutJobs/>
    <Contacts/>
    <Footer/>
    </>
  )
}

export default AboutPage