const Success = () => {
  return (
    <svg
      width="202"
      height="202"
      viewBox="0 0 202 202"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M202 101C202 156.781 156.781 202 101 202C45.2192 202 0 156.781 0 101C0 45.2192 45.2192 0 101 0C156.781 0 202 45.2192 202 101Z"
        fill="#345ABE"
        fill-opacity="0.14"
      />
      <path
        d="M48.875 100.5L86.625 138.25L162.125 62.75"
        stroke="#3A4EFF"
        stroke-width="10"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default Success;
