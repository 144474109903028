import About from "../../components/about/About";
import Catalog from "../../components/catalog/Catalog";
import Contacts from "../../components/contacts/Contacts";
import Hero from "../../components/hero/Hero";
import Services from "../../components/services/Services";
import Footer from "../../components/footer/Footer";
import { Box } from "@chakra-ui/react";
    
function Main() {
  return (
    <Box maxW="100vw" overflow="hidden">
      <Hero />
      <Catalog />
      <About />
      <Services />
      <Contacts />
      <Footer />
    </Box>
  );
}

export default Main;
