import { Dispatch } from "react";
import { EnumTypesClients, TypeClientsAction } from "../../types/Clients";
import { $api } from "../../Api";

export const fetchClients = () => {
  return async (dispatch: Dispatch<TypeClientsAction>) => {
    try {
      dispatch({ type: EnumTypesClients.FETCH_CLIENTS });
      const res = await $api(`clients_logo/`);
      dispatch({
        type: EnumTypesClients.FETCH_CLIENTS_SUCCESS,
        payload: res.data,
      });
      const providers = await $api(`providers_logo/`);
      dispatch({
        type: EnumTypesClients.FETCH_CLIENTS_PROVIDERS,
        payload: providers.data,
      });
    } catch (e) {
      dispatch({
        type: EnumTypesClients.FETCH_CLIENTS_ERROR,
        payload: "server error!",
      });
    }
  };
};
