import { Box, Button, Container, Flex, Heading } from "@chakra-ui/react";
import CatalogSvg from "../../assets/svg/CatalogSvg";
import Card from "../UI/Card";
import ShowMoreSvg from "../../assets/svg/ShowMoreSvg";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "../../hooks/useDispatch";
import Skeleton from "../skeleton/Skeleton";

const Catalog = () => {
  const { loading, error, products } = useAppSelector((s) => s.ProductReducer);
  const navigate = useNavigate();
  const handleLink = () => {
    navigate("/products");
  };

  if (error) {
    return <Heading>{error}</Heading>;
  }

  return (
    <Box id="products" bg={"#1F1F1F"} pos={"relative"}>
      <Container
        maxW={"1170px"}
        mx={"auto"}
        p={{ lg: "180px 0", base: "30px 10px" }}
      >
        <Flex
          alignItems={"center"}
          justifyContent={{ md: "space-between", base: "center" }}
        >
          <Heading
            ml={"20px"}
            color={"#FFFFFF"}
            textShadow={" 0px 4px 20px rgba(0, 0, 0, 0.25)"}
            fontSize={{ md: "60px", base: "30px" }}
            fontWeight={"700"}
            fontFamily={"HelveticaBold"}
            textAlign={{ md: "start", base: "center" }}
          >
            Каталог
          </Heading>
          <Button
            onClick={handleLink}
            display={{ md: "flex", base: "none" }}
            alignItems={"center"}
            justifyContent={"center"}
            bg={"transparent"}
            _hover={{ bg: "transparent" }}
            color={"#FFF"}
            fontFamily={"Inter, sans-serif"}
            fontSize={"20px"}
            fontWeight={"400"}
            mt={"20px"}
          >
            Смотреть все <CatalogSvg />
          </Button>
        </Flex>
        {loading && (
          <Flex   gap={{ lg: "65px", base: "15px" }}
          flexWrap={"wrap"}
          justifyContent={{ md: "start", base: "center" }}
          alignItems={"center"}>

            <Skeleton variant="rect" width={230} height={218} />
            <Skeleton variant="rect" width={230} height={218} />
            <Skeleton variant="rect" width={230} height={218} />
            <Skeleton variant="rect" width={230} height={218} />
            <Skeleton variant="rect" width={230} height={218} />
            <Skeleton variant="rect" width={230} height={218} />
            <Skeleton variant="rect" width={230} height={218} />
            <Skeleton variant="rect" width={230} height={218} />
          </Flex>
        )}
        <Flex
          mt={"50px"}
          gap={{ lg: "65px", base: "15px" }}
          flexWrap={"wrap"}
          justifyContent={{ md: "start", base: "center" }}
          alignItems={"center"}
        >
          {products.map((el, idx) => (
            <Card id={el.id} key={idx} image={el.image} name={el.title} />
          ))}
        </Flex>
        <Button
          onClick={handleLink}
          display={{ md: "none", base: "flex" }}
          border={"1px solid #245CED"}
          alignItems={"center"}
          justifyContent={"center"}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
          color={"#FFF"}
          fontFamily={"Inter, sans-serif"}
          fontSize={{ md: "20px", base: "12px" }}
          fontWeight={"400"}
          m={{ md: "20px 0 0 0 ", base: "30px auto" }}
        >
          Смотреть все <ShowMoreSvg />
        </Button>
      </Container>
    </Box>
  );
};

export default Catalog;
