export interface IInitialContacts {
    contacts : any;
    loading : boolean;
    error : null | string
}

export enum EnumTypesContacts  {
    FETCH_CONTACTS = 'FETCH_CONTACTS',
    FETCH_CONTACTS_SUCCESS = 'FETCH_CONTACTS_SUCCESS',
    FETCH_CONTACTS_ERROR = 'FETCH_CONTACTS_ERROR'
}
interface IFetchContacts {
    type : EnumTypesContacts.FETCH_CONTACTS,
  
}

interface IFetchContactsSuccess {
    type : EnumTypesContacts.FETCH_CONTACTS_SUCCESS,
    payload : any
}
interface IFetchContactsError {
    type : EnumTypesContacts.FETCH_CONTACTS_ERROR,
    payload : string | null
}
export type TypeContactsAction = IFetchContacts | IFetchContactsSuccess | IFetchContactsError