import {
  EnumTypesContacts,
  IInitialContacts,
  TypeContactsAction,
} from "../../types/ContactsTypes";

const initialState: IInitialContacts = {
  contacts: {},
  loading: false,
  error: null,
};

export const ContactsReducer = (
  state = initialState,
  action: TypeContactsAction
): IInitialContacts => {
  switch (action.type) {
    case EnumTypesContacts.FETCH_CONTACTS: {
      return { ...state, loading: true };
    }
    case EnumTypesContacts.FETCH_CONTACTS_SUCCESS: {
      return { ...state, loading: false, contacts: action.payload };
    }
    case EnumTypesContacts.FETCH_CONTACTS_ERROR: {
      return { ...state, loading: false, contacts: [], error: action.payload };
    }
    default:
      return state;
  }
};
