import { Box, Button, Container, Flex, Heading } from "@chakra-ui/react";
import PoginationSvg from "../../assets/svg/PoginationSvg";
import { NavLink, useNavigate } from "react-router-dom";
import PriceListSvg from "../../assets/svg/PriceListSvg";
import Card from "../../components/UI/Card";
import Contacts from "../../components/contacts/Contacts";
import { useAppSelector } from "../../hooks/useDispatch";
import Footer from "../../components/footer/Footer";
import Skeleton from "../../components/skeleton/Skeleton";

const CatalogPage = () => {
  const { loading, error, products } = useAppSelector((s) => s.ProductReducer);
  const navigate = useNavigate();
  const hadleLink = () => {
    navigate("/price-list");
    window.scroll(0, 0);
  };

  if (error) {
    return <Heading>{error}</Heading>;
  }
  console.log(products);

  return (
    <Box bg={"#1F1F1F"}>
      <Box className="catalog">
        <Container
          maxW={"1103px"}
          mx={"auto"}
          p={{ lg: "226px 0 106px 0", base: "100px 10px 0 10px" }}
        >
          <Flex
            display={{ md: "flex", base: "none" }}
            gap={"16px"}
            alignItems={"center"}
            color={"#FFF"}
            fontSize={"16px"}
            fontFamily={"Inter, sans-serif"}
            fontWeight={"400"}
          >
            <NavLink to={"/"}>Главная</NavLink>
            <PoginationSvg />
            Каталог
          </Flex>
          <Flex
            flexDir={{ md: "row", base: "column" }}
            alignItems={"center"}
            justifyContent={"space-between"}
          >
            <Heading
              color={"#FFFFFF"}
              textShadow={" 0px 4px 20px rgba(0, 0, 0, 0.25)"}
              fontSize={{ md: "60px", base: "30px" }}
              fontWeight={"700"}
              fontFamily={"HelveticaBold"}
              mx={{ md: "0", base: "auto" }}
            >
              Каталог
            </Heading>
            <Button
              onClick={hadleLink}
              alignItems={"center"}
              justifyContent={"center"}
              bg={"#fff"}
              rounded={"8px"}
              gap={"16px"}
              height={{ md: "60px", base: "40px" }}
              _hover={{ bg: "#fff" }}
              color={"#4F36FF"}
              fontFamily={"Inter, sans-serif"}
              fontSize={{ md: "20px", base: "14px" }}
              fontWeight={"700"}
              mt={"20px"}
              w={{ md: "200px", base: "100%" }}
            >
              Ассортимент <PriceListSvg />
            </Button>
          </Flex>
          <Flex
            mt={"50px"}
            gap={{ lg: "61px", base: "15px" }}
            flexWrap={"wrap"}
            justifyContent={{ md: "start", base: "center" }}
            alignItems={"center"}
          >
            {loading && (
              <Box display={'flex'}    gap={{ lg: "61px", base: "15px" }}
              flexWrap={"wrap"}
              justifyContent={{ md: "start", base: "center" }}
              alignItems={"center"}>
                <Skeleton variant="rect" width={230} height={258} />
                <Skeleton variant="rect" width={230} height={258} />
                 <Skeleton variant="rect" width={230} height={258} />
                 <Skeleton variant="rect" width={230} height={258} />
                 <Skeleton variant="rect" width={230} height={258} />
                 <Skeleton variant="rect" width={230} height={258} />
                 <Skeleton variant="rect" width={230} height={258} />
                 <Skeleton variant="rect" width={230} height={258} />
              </Box>
            )}
            {products.map((el, idx) => (
              <Card id={el.id} key={idx} image={el.image} name={el.title} />
            ))}
          </Flex>
        </Container>
      </Box>
      <Contacts />
      <Footer />
    </Box>
  );
};

export default CatalogPage;
