import { EnumProductsTypes, IinitialProducts, TypeProductsAction } from "../../types/ProductsTypes"

const initialState : IinitialProducts= {
    products : [],
    loading : false,
    error : null
}

export const ProductReducer  = ( state = initialState, action: TypeProductsAction ) : IinitialProducts => {
    switch(action.type) {
        case EnumProductsTypes.FETCH_PRODUCTS :{
            return {...state, loading : true}
        }
        case EnumProductsTypes.FETCH_PRODUCTS_SUCCESS :{
            return {...state, products : action.payload, loading : false, error : null}
        }
        case EnumProductsTypes.FETCH_PRODUCTS_ERROR : {
            return {...state, products : [] , error: action.payload, loading : false }
        }

        default : return state
        
    }
}