const PhoneLocSvg = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.7626 22C17.9029 22 15.2125 21.4592 12.6915 20.3775C10.1706 19.2958 7.97075 17.8152 6.09212 15.9357C4.21349 14.0569 2.72862 11.8569 1.63752 9.3357C0.546415 6.8145 0.00123084 4.11547 0.00196411 1.2386C-0.0163676 0.9086 0.0936225 0.619667 0.331935 0.3718C0.570247 0.123933 0.863554 0 1.21186 0H5.50147C5.83144 0 6.11558 0.123933 6.3539 0.3718C6.59221 0.619667 6.71136 0.9174 6.71136 1.265C6.71136 2.07167 6.76636 2.8325 6.87635 3.5475C6.98634 4.2625 7.16049 4.94083 7.3988 5.5825C7.49046 5.78417 7.50879 5.99023 7.4538 6.2007C7.3988 6.41117 7.28881 6.60843 7.12383 6.7925L4.37407 9.5425C5.16234 11.1558 6.28057 12.6867 7.72877 14.135C9.17698 15.5833 10.7352 16.7383 12.4034 17.6L15.1531 14.85C15.3181 14.685 15.5106 14.575 15.7306 14.52C15.9505 14.465 16.1705 14.4833 16.3905 14.575C17.0688 14.795 17.7701 14.9648 18.4946 15.0843C19.2191 15.2038 19.9659 15.2632 20.7351 15.2625C21.0834 15.2625 21.3815 15.3908 21.6293 15.6475C21.8772 15.9042 22.0007 16.2158 22 16.5825V20.79C22 21.12 21.8808 21.4042 21.6425 21.6425C21.4042 21.8808 21.1109 22 20.7626 22ZM14.4382 18.7C15.2631 19.0117 16.1247 19.2592 17.0229 19.4425C17.9212 19.6258 18.8469 19.745 19.8002 19.8V17.435C19.2319 17.3983 18.6497 17.3342 18.0535 17.2425C17.4574 17.1508 16.8664 17.0133 16.2805 16.83L14.4382 18.7ZM19.8002 11C19.8002 8.54333 18.9478 6.4625 17.2429 4.7575C15.5381 3.0525 13.4574 2.2 11.001 2.2V0C12.5225 0 13.9524 0.288933 15.2906 0.8668C16.6288 1.44467 17.7929 2.22823 18.7828 3.2175C19.7727 4.2075 20.5566 5.37167 21.1344 6.71C21.7122 8.04833 22.0007 9.47833 22 11H19.8002ZM15.4006 11C15.4006 9.79 14.9698 8.75417 14.1082 7.8925C13.2466 7.03083 12.2109 6.6 11.001 6.6V4.4C12.8341 4.4 14.3923 5.04167 15.6756 6.325C16.9588 7.60833 17.6004 9.16667 17.6004 11H15.4006ZM3.30167 7.5075L5.11651 5.665C4.96985 5.13333 4.84593 4.57417 4.74474 3.9875C4.64355 3.40083 4.57499 2.805 4.53906 2.2H2.22926C2.26593 3.08 2.37592 3.96 2.55924 4.84C2.74255 5.72 2.99003 6.60917 3.30167 7.5075Z"
        fill="white"
      />
    </svg>
  );
};

export default PhoneLocSvg;
