import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import Services1 from "../../assets/image/services1.webp";
import Services2 from "../../assets/image/services2.webp";
import ServicesSvg from "../../assets/svg/ServicesSvg";

const Services = () => {
  const data = [
    {
      image: Services1,
      title: "Резка металла",
      text: "Резка металла подразделяется на несколько видов: термическая, ленточно-пильная, газокислородная, плазменная, лазерная, гидроабразивная. От того, каким способом порезан металл, зависит специфика дальнейших работ.",
    },
    {
      image: Services2,
      title: "Доставка",
      text: "Надежный и быстрый способ получить нужный вам материал прямо на вашу стройплощадку или в офис. Мы предлагаем точные и своевременные доставки. Надежность и качество - наши приоритеты в каждой поставке.",
    },
 
  ];
  return (
    <Box id="services" bg={"#1F1F1F"} pos={"relative"} >
    
      <Container maxW={"1100px"} mx={"auto"} p={{lg:"180px 0", base:"40px 20px"}}>
        <Heading
          color={"#FFF"}
          textShadow={"0px 4px 20px rgba(0, 0, 0, 0.25)"}
          fontSize={{md:"60px", base:"35px"}}
          fontFamily={"HelveticaBold"}
          fontWeight={"700"}
          textAlign={{lg : "start", base:"center"}}
        >
          Услуги
        </Heading>
        <Flex mt={"50px"} justifyContent={{lg:"space-between", base:"center"}} alignItems={'center'} gap={{lg:"0", base:"40px"}}  flexWrap={'wrap'}>
          {data.map((el, idx) => (
            <Box
              key={idx}
              bg={"#FFF"}
              rounded={"30px"}
              maxW={"522px"}
              padding={{md:"31px 24px 59px 23px", base:"15px 15px 25px 15px"}}
            >
              <Image src={el.image} alt="services" />
              <Heading
                color={"#1F1F1F"}
                lineHeight={"18.6px"}
                fontSize={{md:"24px", base:"18px"}}
                fontFamily={"HelveticaBold"}
                fontWeight={700}
                m={"46px 0 20px 0"}
              >
                {el.title}
              </Heading>
              <Text
                color={"#1F1F1F"}
                maxW={"430px"}
                fontSize={"14px"}
                fontFamily={"HelveticaRegular"}
                fontWeight={"400"}
              >
                {el.text}
              </Text>
              <a href="tel:+996701007005">
                    <Button
                color={"#245CED"}
                fontFamily={"Inter,sans-serif"}
                fontWeight={"400"}
                fontSize={{md:"20px", base:"12px"}}
                bg={"transparent"}
                _hover={{ bg: "transparent" }}
                m={{md:"100px 0 0 0", base:"24px auto 0"}}
                display={"flex"}
                gap={"6px"}
                justifyContent={"start"}
                alignItems={"center"}
              >
                Позвонить <ServicesSvg />
              </Button>
              </a>
          
            </Box>
          ))}
        </Flex>
      </Container>
    </Box>
  );
};

export default Services;
