import { Box, Container, Flex, Heading, Image, Text } from "@chakra-ui/react";
import PoginationSvg from "../../assets/svg/PoginationSvg";
import { NavLink } from "react-router-dom";

import "./About.css";
import { useAppSelector } from "../../hooks/useDispatch";
import { useActionClients } from "../../hooks/useActions";
import { useEffect } from "react";

const AboutVideo = () => {
  const {providers} = useAppSelector(s => s.ClientsReducer)
  const {fetchClients} = useActionClients()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(()=> {fetchClients()},[])
  return (
    <Box bg={"#1F1F1F"} p={{ md: "225px 0 0 0", base: "100px 0 0 0" }}>
      <Container maxW={"1165px"} m={"0 auto"}>
        <Flex
          display={{ md: "flex", base: "none" }}
          gap={"16px"}
          alignItems={"center"}
          color={"#FFF"}
          fontSize={"16px"}
          fontFamily={"Inter, sans-serif"}
          fontWeight={"400"}
        >
          <NavLink to={"/"}>Главная</NavLink>
          <PoginationSvg />О компании
        </Flex>
        <Heading
          color={"#FFFFFF"}
          textShadow={" 0px 4px 20px rgba(0, 0, 0, 0.25)"}
          fontSize={{ md: "60px", base: "30px" }}
          fontWeight={"700"}
          textAlign={{ md: "start", base: "center" }}
          fontFamily={"HelveticaBold"}
        >
          О компании
        </Heading>
        <Box
          display={"flex"}
          flexDir={{ md: "row", base: "column" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          m={{ md: "50px 0 140px 0", base: "40px 0 " }}
          gap={{ md: "76px", base: "40px" }}
        >
          <iframe
            className="iframe"
            width="535"
            height="438"
            src="https://www.youtube.com/embed/8XtGHLvrY7Q"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
          <Text
            maxW={"554px"}
            color={"#fff"}
            textShadow={" 0px 4px 20px rgba(0, 0, 0, 0.25)"}
            fontSize={{ md: "25px", base: "16px" }}
            fontFamily={"HelveticaRegular"}
            lineHeight={"normal"}
          >
            С нашим многолетним опытом и прочными партнерскими связями в
            металлургической отрасли, мы гордимся тем, что можем предложить
            клиентам металлопродукцию высокой прочности, надежности и
            функциональности.
            <br />
            <br />
            Наш ассортимент охватывает всё: от стандартных изделий, таких как
            листовой металл, трубы и профили, до специализированных компонентов
            для уникальных проектов.
          </Text>
        </Box>

        <Heading
          color={"#FFFFFF"}
          textShadow={" 0px 4px 20px rgba(0, 0, 0, 0.25)"}
          fontSize={{ md: "60px", base: "30px" }}
          fontWeight={"700"}
          textAlign={{ md: "start", base: "center" }}
          fontFamily={"HelveticaBold"}
          pb={{md:"50px", base:"34px"}}
        >
          Наши поставщики
        </Heading>
    
      </Container>
      <Box className="marquee-infinite">
        <Box>
          <Box as="span">
            {providers.map((img, idx) => (
             <Image key={idx} src={img.image} alt="image" w={"167px"} h={{md:"80px", base:"32px"}} objectFit={'contain'} />
            ))}
          </Box>
     
        </Box>
      </Box>
      <Box className="marquee-infinite2" mt={'30px'}>
        <Box >
          <Box as="span">
            {providers.map((img, idx) => (
             <Image key={idx} src={img.image} alt="image" w={"167px"} h={{md:"80px", base:"32px"}} objectFit={'contain'} />
            ))}
          </Box>
        </Box>
      </Box>


      <Container maxW={"1165px"} m={"0 auto"} p={{md:"", base:"20px"}}>
        <Box
          display={"flex"}
          flexDir={{ md: "row", base: "column-reverse" }}
          justifyContent={"space-between"}
          alignItems={"center"}
          m={{ md: "160px 0 180px 0", base: "40px 0 0 0" }}
          gap={{ md: "76px", base: "40px" }}
        >
          <Text
            maxW={"554px"}
            color={"#fff"}
            textShadow={" 0px 4px 20px rgba(0, 0, 0, 0.25)"}
            fontSize={{ md: "25px", base: "16px" }}
            fontFamily={"HelveticaRegular"}
            lineHeight={"normal"}
          >
            Мы понимаем, что каждый клиент имеет свои уникальные потребности, и
            поэтому наша команда специалистов готова работать с вами, чтобы
            предоставить индивидуальные решения под ваши требования.
            <br />
            <br />
            Независимо от масштаба вашего проекта – от крупных промышленных
            заказов до мелких партий – мы обеспечим вам качественной продукцией
            и высоким уровнем обслуживания. Ваш успех – наша цель!
          </Text>
          <iframe
            className="iframe"
            width="535"
            height="438"
            src="https://www.youtube.com/embed/8XtGHLvrY7Q"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          ></iframe>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutVideo;
