import {
  EnumTypesClients,
  IInitialClients,
  TypeClientsAction,
} from "../../types/Clients";

const initialState: IInitialClients = {
  clients: [],
  providers : [],
  loading: false,
  error: null,
};

export const ClientsReducer = (
  state = initialState,
  action: TypeClientsAction
): IInitialClients => {
  switch (action.type) {
    case EnumTypesClients.FETCH_CLIENTS: {
      return { ...state, loading: true };
    }
    case EnumTypesClients.FETCH_CLIENTS_SUCCESS: {
      return { ...state, loading: false, clients: action.payload };
    }
    case EnumTypesClients.FETCH_CLIENTS_ERROR: {
      return { ...state, loading: false, clients: [], error: action.payload };
    }
    case EnumTypesClients.FETCH_CLIENTS_PROVIDERS : {
      return {...state, loading : false, providers : action.payload}
    }
    default:
      return state;
  }
};
