import React from "react";

const PhoneSvg = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="16.5" cy="16.5" r="16" stroke="#F2F2F4" />
      <path
        d="M18.1887 13.3102C18.85 13.439 19.4578 13.7618 19.9342 14.2374C20.4107 14.7129 20.7341 15.3195 20.8631 15.9796L18.1887 13.3102ZM18.1887 10.6071C19.5626 10.7595 20.8439 11.3736 21.822 12.3486C22.8002 13.3237 23.4171 14.6017 23.5714 15.9728L18.1887 10.6071ZM22.8944 21.3656V23.3929C22.8951 23.5811 22.8565 23.7674 22.7809 23.9399C22.7054 24.1123 22.5946 24.2671 22.4557 24.3943C22.3167 24.5216 22.1527 24.6184 21.974 24.6787C21.7954 24.739 21.6061 24.7614 21.4183 24.7445C19.3348 24.5185 17.3335 23.8079 15.5752 22.6698C13.9392 21.6323 12.5522 20.2479 11.5127 18.6151C10.3684 16.8522 9.65631 14.8449 9.43407 12.7561C9.41715 12.5692 9.4394 12.3809 9.49941 12.2031C9.55941 12.0252 9.65586 11.8618 9.7826 11.7233C9.90935 11.5847 10.0636 11.4739 10.2356 11.3981C10.4076 11.3223 10.5935 11.2831 10.7815 11.2829H12.8127C13.1413 11.2797 13.4598 11.3958 13.709 11.6097C13.9581 11.8235 14.1208 12.1205 14.1668 12.4452C14.2526 13.094 14.4116 13.7311 14.6408 14.3442C14.7319 14.5861 14.7516 14.8489 14.6976 15.1017C14.6436 15.3544 14.5181 15.5864 14.3361 15.7701L13.4762 16.6283C14.4401 18.3202 15.8436 19.721 17.5387 20.683L18.3986 19.8248C18.5827 19.6431 18.8151 19.5179 19.0683 19.464C19.3215 19.4101 19.5849 19.4298 19.8272 19.5207C20.4415 19.7495 21.0797 19.9082 21.7298 19.9937C22.0587 20.04 22.3591 20.2054 22.5738 20.4583C22.7885 20.7113 22.9026 21.0342 22.8944 21.3656Z"
        stroke="#F2F2F4"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default PhoneSvg;
