import {
  Box,
  Button,
  Container,
  Flex,
  Heading,
  Image,
  Text,
} from "@chakra-ui/react";
import CatalogSvg from "../../assets/svg/CatalogSvg";
import about2 from "../../assets/image/about14.webp";
import { useNavigate } from "react-router-dom";
import ShowMoreSvg from "../../assets/svg/ShowMoreSvg";

const About = () => {
    const navigate = useNavigate()
    const Navigate = () =>{
        navigate('/about')
        window.scroll(0,0)
    }
  return (
    <Box id="about" bg={"#1F1F1F"} mx={'auto'} pos={"relative"}>
      <Box className="about">
    
        <Container maxW={"1160px"} mx={"auto"} p={{lg:"180px 0", base:"40px 20px"}}>
          <Flex alignItems={"center"} justifyContent={{md:"space-between", base:"center"}}>
            <Heading
              color={"#FFFFFF"}
              textShadow={" 0px 4px 20px rgba(0, 0, 0, 0.25)"}
              fontSize={{md:"60px", base:"30px"}}
              fontWeight={"700"}
              fontFamily={"HelveticaBold"}
             
            >
              О компании
            </Heading>
            <Button
            onClick={Navigate}
            
              display={{md:"flex" , base: "none"}}
              alignItems={"center"}
              justifyContent={"center"}
              bg={"transparent"}
              _hover={{ bg: "transparent" }}
              color={"#FFF"}
              fontFamily={"Inter, sans-serif"}
              fontSize={{md:"20px", base:"16px"}}
              fontWeight={"400"}
              mt={"20px"}
            >
              Смотреть все <CatalogSvg />
            </Button>
          </Flex>
          <Box
            mt={"76px"}
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            flexDirection={{md:"row", base: "column-reverse"}}
            gap={{md:"0", base:"40px 0 "}}
          >
            <Text
              maxW={"564px"}
              fontSize={{lg:"19px", base:"16px"}}
              textShadow={"0px 4px 20px rgba(0, 0, 0, 0.25)"}
              color={"#FFF"}
              fontFamily={"Inter"}
              fontWeight={"400"}
            >
              С нашим многолетним опытом и прочными партнерскими связями в
              металлургической отрасли, мы гордимся тем, что можем предложить
              клиентам металлопродукцию высокой прочности, надежности и
              функциональности. <br/> <br/> Наш ассортимент охватывает всё: <span style={{fontWeight: '800', color:"#fff"}}> от стандартных
              изделий, таких как листовой металл, трубы и профили, до
              специализированных компонентов для уникальных проектов.</span>
            </Text>
            <Image src={about2} alt="about" />
          </Box>
          <Button
          onClick={Navigate}
          display={{ md: "none", base: "flex" }}
          border={"1px solid #245CED"}
          alignItems={"center"}
          justifyContent={"center"}
          bg={"transparent"}
          _hover={{ bg: "transparent" }}
          color={"#FFF"}
          fontFamily={"Inter, sans-serif"}
          fontSize={{ md: "20px", base: "12px" }}
          fontWeight={"400"}
          m={{md:"20px 0 0 0 ", base:"30px auto"}}
        >
          Смотреть все <ShowMoreSvg />
        </Button>
        </Container>
      </Box>
    </Box>
  );
};

export default About;
