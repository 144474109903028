import { Box, Container, Heading, Image } from "@chakra-ui/react";
import slide1 from "../../assets/image/slide1.webp";
import slide2 from "../../assets/image/slide2.webp";
import slide3 from "../../assets/image/slide3.webp";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { FC } from "react";
import "../AboutVideo/About.css";
import { useAppSelector } from "../../hooks/useDispatch";

const AboutJobs: FC = () => {
  const { clients } = useAppSelector((s) => s.ClientsReducer);
  const data = [slide1, slide2, slide3, slide1, slide2, slide3];
  var settings = {
    dots: true,
    infinite: false,
    speed: 300,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  console.log(clients, "clients");

  return (
    <Box bg={"#1F1F1F"}>
      <Container
        maxW={"1079px"}
        mx={"auto"}
        p={{ md: "none", base: "0px 30px 30px 30px" }}
      >
        <Heading
          color={"#fff"}
          textShadow={"0px 4px 20px rgba(0, 0, 0, 0.25)"}
          fontSize={{ md: "60px", base: "30px" }}
          fontFamily={"HelveticaBold"}
          pb={"50px"}
          textAlign={{ md: "start", base: "center" }}
        >
          Наши работы
        </Heading>

        <Slider {...settings}>
          {data.map((el: any, idx: number) => (
            <Image key={idx} src={el} alt="our jobs" w={"80%"} h={"250px"} />
          ))}
        </Slider>

        <Heading
          color={"#FFFFFF"}
          textShadow={" 0px 4px 20px rgba(0, 0, 0, 0.25)"}
          fontSize={{ md: "60px", base: "30px" }}
          fontWeight={"700"}
          textAlign={{ md: "start", base: "center" }}
          fontFamily={"HelveticaBold"}
          pb={{ md: "50px", base: "34px" }}
          pt={{ md: "120px", base: "60px" }}
        >
          Наши клиенты
        </Heading>
      </Container>

      <Box className="marquee-infinite">
        <Box>
          <Box as="span">
            {clients?.map((img, idx) => (
              <Image
                key={idx}
                src={img.image}
                alt="image"
                w={"157px"}
                h={{ md: "60px", base: "32px" }}
                objectFit={"contain"}
              />
            ))}
          </Box>
        </Box>
      </Box>
      <Box
        className="marquee-infinite2"
        mt={"30px"}
        pb={{ md: "150px", base: "50px" }}
      >
        <Box>
          <Box as="span">
            {clients?.map((img, idx) => (
              <Image
                key={idx}
                src={img.image}
                alt="image"
                w={"157px"}
                h={{ md: "60px", base: "32px" }}
                objectFit={"contain"}
              />
            ))}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutJobs;
