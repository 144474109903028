import { EnumTypeItem, IInitialItem, TypeActionItem } from "../../types/Item";

const initialState: IInitialItem = {
  item: {},
  loading: false,
  error: null,
};

export const ItemReducer = (
  state = initialState,
  action: TypeActionItem
): IInitialItem => {
  switch (action.type) {
    case EnumTypeItem.FETCH_ITEM: {
      return { ...state, loading: true };
    }
    case EnumTypeItem.FETCH_ITEM_SUCCESS: {
      return { ...state, loading: false, item: action.payload };
    }
    case EnumTypeItem.FETCH_ITEM_ERROR: {
      return { ...state, loading: false, item: [], error: action.payload };
    }

    default:
      return state;
  }
};
